import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Welcome from "./Welcome";
import VolunteerBar from "./VolunteerBar";
import OurMission from "./OurMission";
import OurPrograms from "./OurPrograms";
import SponsorBar from "./SponsorBar";
import SubscribeAndDonate from "./SubscribeAndDonate";
import { getPageBySlug } from "../../services/Wordpress";

const Home = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("home");
        console.log("Fetched data:", data);
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching Home Page data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div>
      <Helmet>
        <title>914United | Welcome</title>
        <meta name="description" content={acfData.home_page_description} />
        <link rel="canonical" href="https://www.914united.com" />
        <meta property="og:title" content="914United | Welcome" />
        <meta
          property="og:description"
          content={acfData.home_page_description}
        />
        <meta property="og:image" content={acfData.home_page_images[0]?.url} />
        <meta property="og:url" content="https://www.914united.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | Welcome" />
        <meta
          name="twitter:description"
          content={acfData.home_page_description}
        />
        <meta name="twitter:image" content={acfData.home_page_images[0]?.url} />
      </Helmet>
      <Welcome
        images={acfData.home_page_images}
        title={acfData.home_page_title}
        description={acfData.home_page_description}
        buttonLink={acfData.home_page_button_link}
        buttonText={acfData.home_page_button_text}
      />
      <VolunteerBar acfData={acfData} />
      <OurMission
        text={acfData.our_mission_description}
        image={acfData.our_mission_image?.url}
        link={acfData.our_mission_url_link}
        buttonText={acfData.our_mission_button_text}
      />
      <OurPrograms
        text={acfData.our_programs_description}
        image={acfData.our_programs_image?.url}
        link={acfData.our_programs_url_link}
        buttonText={acfData.our_programs_button_text}
      />
      <SponsorBar
        text={acfData.announcement_bar_text}
        buttonText={acfData.announcement_bar_button_text}
        buttonLink={acfData.announcement_bar_link}
      />
      <SubscribeAndDonate
        subscribe_title={acfData.subscribe_title}
        subscribe_description={acfData.subscribe_description}
        donate_title={acfData.donate_title}
        donate_description={acfData.donate_description}
        button_text={acfData.donation_button_text}
        mailchimp={acfData.mailchimp_action_url}
      />
    </div>
  );
};

export default Home;
