import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AboutHeader from "./AboutHeader";
import OurMission from "./OurMission";
import OurVision from "./OurVision";
import OurValues from "./OurValues";
import { getPageBySlug } from "../../services/Wordpress";

const AboutUs = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("about");
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching About Us data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div className="bg-white">
      <Helmet>
        <title>914United | About Us</title>
        <meta name="description" content={acfData.about_page_description} />
        <link rel="canonical" href="https://www.914united.com/about" />
        <meta property="og:title" content="914United | About Us" />
        <meta
          property="og:description"
          content={acfData.about_page_description}
        />
        <meta property="og:image" content={acfData.about_page_image?.url} />
        <meta property="og:url" content="https://www.914united.com/about" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | About Us" />
        <meta
          name="twitter:description"
          content={acfData.about_page_description}
        />
        <meta name="twitter:image" content={acfData.about_page_image?.url} />
      </Helmet>
      <AboutHeader
        description={acfData.about_us_description}
        image={acfData.about_us_main_image?.url}
        title={acfData.about_us_title}
      />
      <OurMission
        mission={acfData.our_mission}
        title={acfData.our_mission_title}
      />
      <OurVision
        vision={acfData.our_vision}
        image={acfData.our_vision_image?.url}
        title={acfData.our_vision_title}
      />
      <OurValues values={acfData.our_values} title={acfData.our_values_title} />
      <div className="text-center my-10">
        <Link
          to={acfData.about_us_button_link}
          className="inline-block px-6 py-3 mb-2 bg-red text-white font-semibold rounded-lg shadow-md hover:shadow-xl hover:scale-105 transition duration-300 text-[26px]"
        >
          {acfData.about_us_button_text}
        </Link>
      </div>
    </div>
  );
};

export default AboutUs;
