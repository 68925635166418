import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const OurPrograms = ({ image, text, link, buttonText }) => {
  const textContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            textContainerRef.current.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (textContainerRef.current) {
      observer.observe(textContainerRef.current);
    }

    return () => {
      if (textContainerRef.current) {
        observer.unobserve(textContainerRef.current);
      }
    };
  }, []);

  return (
    <div className="bg-red text-white">
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div>
          <img src={image} alt="landing page" className="w-full object-cover" />
        </div>
        <div
          ref={textContainerRef}
          className="flex flex-col justify-center px-5 md:px-16 py-5 md:py-0 opacity-0 transition-opacity duration-1000"
        >
          <h1 className="text-4xl font-bold uppercase text-center pb-5">
            Our Programs
          </h1>
          <div
            className="mt-4 text-lg leading-relaxed text-center mx-auto"
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <Link
            to={link}
            className="mt-6 inline-block px-8 py-3 bg-white text-red font-bold uppercase border-2 border-white rounded-md"
          >
            {buttonText}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurPrograms;
