import React, { useState, useEffect } from "react";
import NewsHeader from "./NewsHeader";
import FeaturedNews from "./FeaturedNews";
import OtherNews from "./News";
import Media from "./Media";
import { getPageBySlug } from "../../services/Wordpress";
import { Helmet } from "react-helmet";

const News = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("news");
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching News Page data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div className="bg-red text-white">
      <Helmet>
        <title>914United | News and Media</title>
        <meta
          name="description"
          content="Stay updated with the latest news and media from 914United. Check out our featured news, other news articles, and media coverage."
        />
        <link rel="canonical" href="https://www.914united.com/news" />
        <meta property="og:title" content="914United | News and Media" />
        <meta
          property="og:description"
          content="Stay updated with the latest news and media from 914United. Check out our featured news, other news articles, and media coverage."
        />
        <meta property="og:image" content={acfData.main_image?.url} />
        <meta property="og:url" content="https://www.914united.com/news" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | News and Media" />
        <meta
          name="twitter:description"
          content="Stay updated with the latest news and media from 914United. Check out our featured news, other news articles, and media coverage."
        />
        <meta name="twitter:image" content={acfData.main_image?.url} />
      </Helmet>
      <NewsHeader
        image={acfData.main_image}
        title={acfData.news_and_media_title}
      />
      <FeaturedNews
        image={acfData.featured_news_image}
        title={acfData.title}
        category={acfData.category}
        description={acfData.description}
        button_text={acfData.featured_news_button_text}
        url={acfData.featured_news_url}
      />
      <OtherNews news={acfData.news} />
      <Media media={acfData.media} />
    </div>
  );
};

export default News;
