import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getPageBySlug } from "../../services/Wordpress";

const OurPrograms = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("our-programs");
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching Our Programs data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div>
      <Helmet>
        <title>914United | Our Programs</title>
        <meta
          name="description"
          content="Explore the various programs offered by 914United aimed at making a positive impact. Join us as a mentee or volunteer and support our mission."
        />
        <link rel="canonical" href="https://www.914united.com/programs" />
        <meta property="og:title" content="914United | Our Programs" />
        <meta
          property="og:description"
          content="Explore the programs offered by 914United aimed at making a positive impact. Join us as a mentee or volunteer and support our mission."
        />
        <meta property="og:image" content={acfData.our_programs_image?.url} />
        <meta property="og:url" content="https://www.914united.com/programs" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | Our Programs" />
        <meta
          name="twitter:description"
          content="Explore the programs offered by 914United aimed at making a positive impact. Join us as a mentee or volunteer and support our mission."
        />
        <meta name="twitter:image" content={acfData.our_programs_image?.url} />
      </Helmet>
      <div className="relative">
        <img
          src={acfData.our_programs_image?.url}
          alt="landing page"
          className="w-full md:h-[630px] object-cover shadow-lg"
        />
        <div className="absolute inset-0 md:bg-black opacity-55"></div>
        <div className="md:absolute md:top-3/4 left-24 transform  text-center md:text-white  ">
          <h1 className=" text-[40px] md:text-[60px] font-bold pt-5 md:pt-0 ">
            {acfData.our_programs_title}
          </h1>
        </div>
      </div>
      <div className="py-20 bg-red ">
        <div className="space-y-8">
          {acfData.our_programs.map((banner, index) => (
            <div
              key={index}
              className=" mx-auto relative w-[90%] h-auto md:h-[330px] shadow-xl border-2 border-white rounded-md hover:scale-105 transition duration-300"
            >
              <img
                className="md:absolute top-0 left-0 md:w-2/5 md:h-full bg-cover rounded-md"
                src={banner.image.url}
                alt={banner.title}
              />
              <div className="md:absolute top-0 left-0 md:w-3/5 h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="md:absolute top-0 right-0 md:w-3/5 h-full flex flex-col justify-center p-8 bg-white">
                <h2 className="text-3xl font-bold py-2">{banner.title}</h2>
                <p className="text-md font-light">{banner.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="px-5 md:px-0">
          <h1 className="justify-center text-center flex text-white text-[41px] font-bold pt-10 px-5 md:px-0">
            {acfData.banner_text}
          </h1>
          <div className="flex justify-center mt-10">
            {acfData.banner_buttons.map((button, index) => (
              <React.Fragment key={index}>
                {index > 0 && (
                  <h2 className="text-white mx-3 md:mx-10 text-[24px] pt-4">
                    OR
                  </h2>
                )}
                <Link
                  to={button.button_link}
                  target={
                    button.button_link.includes("https://") ? "_blank" : "_self"
                  }
                  className="mt-3 inline-block px-6 py-3 mb-2 bg-white text-red font-semibold rounded-lg shadow-md hover:shadow-xl hover:scale-110 transition duration-300"
                >
                  {button.button_text}
                </Link>
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPrograms;
