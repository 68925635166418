import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getPageBySlug } from "../../services/Wordpress";

const Donate = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("donate");
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching Donate Page data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div className="relative">
      <Helmet>
        <title>914United | Donate</title>
        <meta name="description" content={acfData.description} />
        <link rel="canonical" href="https://www.914united.com/donate" />
        <meta property="og:title" content="914United | Donate" />
        <meta property="og:description" content={acfData.description} />
        <meta property="og:image" content={acfData.main_image?.url} />
        <meta property="og:url" content="https://www.914united.com/donate" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | Donate" />
        <meta name="twitter:description" content={acfData.description} />
        <meta name="twitter:image" content={acfData.main_image?.url} />
      </Helmet>
      <img
        src={acfData.main_image?.url}
        alt="landing page"
        className="w-full md:h-[550px] object-cover shadow-lg"
      />
      <div className="md:h-[300px] bg-red"></div>
      <div className="md:absolute inset-0 bg-black opacity-55"></div>
      <div className="md:absolute md:top-[50%] md:left-1/2 transform md:-translate-x-1/2 md:-translate-y-1/2 text-center text-black bg-white border-2 border-red  px-10  shadow-xl py-5">
        <h1 className="text-4xl font-bold ">{acfData.title}</h1>
        <div
          className="mt-4 md:container md:px-10 md:w-[600px]"
          dangerouslySetInnerHTML={{ __html: acfData.description }}
        />
        <Link
          to={acfData.donation_link}
          className="mt-6 inline-block px-6 py-3 mb-2 bg-red text-white font-semibold rounded-lg shadow-md hover:shadow-xl hover:scale-105 transition duration-300"
        >
          Donate!
        </Link>
      </div>
    </div>
  );
};

export default Donate;
