import React, { useEffect, useRef } from "react";

const OurValues = ({ values, title }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            textRef.current.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <div className="container mx-auto px-10 py-10">
      <div
        ref={textRef}
        className="opacity-0 transition-opacity duration-1000 md:opacity-100"
      >
        <h1 className="text-[50px] font-bold text-right mt-10">{title}</h1>
        <p className="mt-4 text-xl md:text-2xl text-left md:text-right">
          {values}
        </p>
      </div>
    </div>
  );
};

export default OurValues;
