import React, { useEffect, useRef } from "react";

const OurVision = ({ vision, image, title }) => {
  const textRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            textRef.current.classList.add("slide-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => {
      if (textRef.current) {
        observer.unobserve(textRef.current);
      }
    };
  }, []);

  return (
    <div className="bg-red text-white">
      <div className="">
        <div className="grid grid-cols-12 gap-8">
          <div
            ref={textRef}
            className="col-span-12 md:col-span-6 pt-10 container px-8 md:px-28 transform transition-transform duration-1000 md:translate-x-full"
          >
            <h1 className="text-[30px] md:text-[40px] font-bold text-left mt-10">
              {title}
            </h1>
            <p className="mt-4 pb-5 md:pb-20 text-lg md:text-xl">{vision}</p>
          </div>
          <img
            className="col-span-12 md:col-span-6 md:object-cover md:h-[460px] w-full z-10"
            src={image}
          />
        </div>
      </div>
    </div>
  );
};

export default OurVision;
