import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Welcome = ({ images, title, description, buttonText, buttonLink }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    fade: true,
    autoplay: true,
    swipe: false,
  };

  return (
    <div className="relative bg-red">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img
              src={image.image.url}
              alt={`landing page ${index}`}
              className="w-full h-[260px] md:h-[530px] object-cover object-top shadow-lg"
            />
          </div>
        ))}
      </Slider>
      <div className="md:absolute inset-0 md:bg-black md:opacity-30 "></div>
      <div className="md:absolute md:top-3/4 md:left-1/4 transform md:-translate-x-1/2 md:-translate-y-1/2 text-center text-white bg-black px-6 py-4 shadow-xl bg-opacity-40 rounded-md">
        <h1 className="text-4xl font-bold">{title}</h1>
        <p className="mt-4 container px-3 md:px-10 md:w-[500px] text-center mx-auto">
          {description}
        </p>
        <Link
          to={buttonLink}
          target={buttonLink.includes("http") ? "_blank" : "_self"}
          className="mt-6 inline-block px-6 py-3 mb-2 bg-white text-black font-semibold rounded-lg shadow-md hover:shadow-xl transition duration-300"
        >
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default Welcome;
