import React from "react";

const StoryBody = ({ text, title }) => {
  return (
    <div>
      <div className="md:p-20 p-5">
        <h1 className="text-5xl font-bold">{title}</h1>
        <hr className="bg-white my-5" />

        <div
          className="py-10 mx-auto text-xl px-5 md:px-20 "
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
};

export default StoryBody;
