import React from "react";
import { Link } from "react-router-dom";

const VolunteerBar = ({ text, buttonText, buttonLink }) => {
  return (
    <div className="bg-white text-red shadow-inner py-5 md:py-2 md:h-[90px] container  ">
      <div className="text-center font-bold text-[18px] md:text-[24px] align-middle ">
        <div className="overflow-hidden whitespace-nowrap block md:inline-flex md:max-w-[80%] pr-3 ">
          <span className="announcement-text py-2 md:py-0 pt-0 md:pt-4 mr-4 md:mr-10">
            {text}
          </span>
        </div>

        <Link
          to={buttonLink}
          target="_blank"
          className="mt-3 inline-block px-4 py-2 mb-2 bg-red text-white font-semibold rounded-lg shadow-md hover:shadow-xl hover:scale-105 transition duration-300 text-[24px]"
        >
          {buttonText}
        </Link>
      </div>
    </div>
  );
};

export default VolunteerBar;
