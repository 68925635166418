import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { getPageBySlug } from "../../services/Wordpress";

const Contact = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("contact");
        console.log("Fetched data:", data);
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching Home Page data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div>
      <Helmet>
        <title>914United | Contact Us</title>
        <meta name="description" content={acfData.description} />
        <link rel="canonical" href="https://www.914united.com/contact" />
        <meta property="og:title" content="914United | Contact Us" />
        <meta property="og:description" content={acfData.description} />
        <meta
          property="og:image"
          content="https://914united.com/wp-content/uploads/2022/09/session1.jpg"
        />
        <meta property="og:url" content="https://www.914united.com/contact" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | Contact Us" />
        <meta name="twitter:description" content={acfData.description} />
        <meta
          name="twitter:image"
          content="https://914united.com/wp-content/uploads/2022/09/session1.jpg"
        />
      </Helmet>
      <div className="relative">
        <img
          src={acfData.main_image?.url}
          alt="landing page"
          className="w-full md:h-[530px] object-cover shadow-lg"
        />
        <div className="absolute inset-0 md:bg-black opacity-55"></div>
        <div className="md:absolute top-3/4 left-24 transform text-center md:text-white">
          <h1 className="text-[40px] md:text-[60px] font-bold">
            {acfData.contact_us_title}
          </h1>
        </div>
      </div>
      <div className="md:grid md:grid-cols-12 mx-auto px-5 md:px-20">
        <div className="md:col-span-5 md:pt-10">
          <div
            className="text-2xl my-10"
            dangerouslySetInnerHTML={{ __html: acfData.description }}
          />
          <h1 className="text-center text-[24px] font-bold">
            {acfData.contact_either_text}
          </h1>
          <div className="md:flex text-center justify-center py-5 md:py-10">
            {acfData.contact_names.map((contact, index) => (
              <React.Fragment key={index}>
                {index > 0 && <p className="md:px-10 pb-5 md:pb-0 pt-5">OR</p>}
                <h1 className="text-[18px]">
                  <strong>{contact.button_text}</strong>{" "}
                  <a
                    href={`mailto:${contact.button_link}`}
                    className="underline text-blue-600"
                  >
                    {contact.button_link}
                  </a>
                </h1>
              </React.Fragment>
            ))}
          </div>
        </div>
        <form
          action={acfData.form_submit_url}
          method="POST"
          className="md:col-span-7 shadow-xl md:mx-10 my-10 bg-red rounded-md px-10 py-10"
        >
          <input
            type="text"
            placeholder="First Name"
            name="name"
            className="w-full p-2 my-2"
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            name="text"
            className="w-full p-2 my-2"
          />
          <input
            type="email"
            placeholder="Email"
            name="email"
            className="w-full p-2 my-2"
            required
          />
          <input
            type="text"
            placeholder="Subject"
            name="text-1"
            className="w-full p-2 my-2"
          />
          <textarea
            placeholder="Message"
            rows="4"
            cols="50"
            name="message"
            className="w-full p-2 my-2"
            required
          />
          <input
            type="submit"
            value="Submit"
            className="w-full p-2 my-2 bg-white text-red"
          />

          <input type="hidden" value="" name="recaptchaResponse" />
          <input
            type="hidden"
            name="formServices"
            value="fe5c36a2-4654-d6a7-3e8f-766d42f4bb31"
          />
        </form>
      </div>
    </div>
  );
};

export default Contact;
