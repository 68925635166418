import React, { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";

Modal.setAppElement("#root"); // Assuming your root element has an id of 'root'

const OtherNews = ({ news }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const openModal = (article) => {
    setSelectedArticle(article);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedArticle(null);
  };

  return (
    <div className="bg-red mx-10 md:px-10">
      <div className="grid md:grid-cols-3 gap-4">
        {news.map((article, index) => (
          <div key={index} className="p-1 mb-10 bg-white text-black rounded-md">
            <img
              className="w-full h-[300px] object-cover object-top rounded-t-md"
              src={article.image.url}
              alt={article.title}
            />
            <div className="p-2">
              <div className="font-bold text-xl mb-2 text-center">
                {article.title}
              </div>
              <p className="text-center pb-5">{article.short_description}</p>
            </div>
            <button
              onClick={() => openModal(article)}
              className="bg-red hover:bg-white hover:text-red border border-red text-white font-bold py-2 px-4 rounded my-5 mx-auto justify-center items-center flex"
            >
              Read More
            </button>
          </div>
        ))}
      </div>

      {selectedArticle && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Article Modal"
          className="fixed inset-0 flex items-center justify-center p-4 bg-opacity-80 bg-black mt-10 "
        >
          <div className="bg-white rounded-md max-w-lg mx-auto p-5">
            <button
              onClick={closeModal}
              className="bg-red text-white font-bold py-2 px-4 rounded float-right block md:hidden"
            >
              Close
            </button>
            <h2 className="text-xl font-bold mb-4">{selectedArticle.title}</h2>
            <img
              className="w-full md:h-[300px] object-cover object-top rounded-t-md mb-4"
              src={selectedArticle.image.url}
              alt={selectedArticle.title}
            />
            <div
              className="overflow-y-auto"
              dangerouslySetInnerHTML={{
                __html: selectedArticle.long_description,
              }}
            />
            <button
              onClick={closeModal}
              className="bg-red text-white font-bold py-2 px-4 rounded float-right md:block hidden"
            >
              Close
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OtherNews;
