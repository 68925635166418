import React, { useEffect, useRef } from "react";

const OurMission = ({ mission, title }) => {
  const missionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            missionRef.current.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (missionRef.current) {
      observer.observe(missionRef.current);
    }

    return () => {
      if (missionRef.current) {
        observer.unobserve(missionRef.current);
      }
    };
  }, []);

  return (
    <div className="container mx-auto px-5 md:px-10 py-20 text-center">
      <div
        ref={missionRef}
        className="opacity-0 transition-opacity duration-1000"
      >
        <h1 className="text-[40px] md:text-[50px] font-bold text-center">
          {title}
        </h1>
        <p className="mt-4 text-xl md:text-2xl">{mission}</p>
      </div>
    </div>
  );
};

export default OurMission;
