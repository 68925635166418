import React from "react";

const AboutHeader = ({ description, image, title }) => {
  return (
    <div className="relative">
      <img
        src={image}
        alt="landing page"
        className="w-full md:h-[650px] object-cover shadow-lg"
      />
      <div className="absolute inset-0 md:bg-black opacity-55"></div>
      <div className="md:absolute md:top-[60%] md:left-24 md:transform  md:text-white text-center md:text-left pt-5 md:pt-0 ">
        <h1 className="text-[40px] md:text-[60px] font-bold ">{title}</h1>
        <h1 className="text-[20px] md:text-[30px] md:w-[60%]">{description}</h1>
      </div>
    </div>
  );
};

export default AboutHeader;
