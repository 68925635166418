import React from "react";

const NotFound = () => {
  return (
    <div className="bg-white">
      <div className="relative">
        <h1 className="text-5xl py-10 text-center "> 404 PAGE NOT FOUND </h1>
      </div>
    </div>
  );
};

export default NotFound;
