import React, { useState, useEffect } from "react";
import StoryHeader from "./StoryHeader";
import StoryBody from "./StoryBody";
import FounderBody from "./FounderBody";
import { getPageBySlug } from "../../services/Wordpress";
import { Helmet } from "react-helmet";

const OurStory = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("our-story");
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching Our Story data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div>
      <Helmet>
        <title>914United | Our Story</title>
        <meta
          name="description"
          content="Learn about the inspiring story behind 914United, our mission, and the founders who made it possible."
        />
        <link rel="canonical" href="https://www.914united.com/our-story" />
        <meta property="og:title" content="914United | Our Story" />
        <meta
          property="og:description"
          content="Learn about the inspiring story behind 914United, our mission, and the founders who made it possible."
        />
        <meta property="og:image" content={acfData.our_story_image?.url} />
        <meta property="og:url" content="https://www.914united.com/our-story" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | Our Story" />
        <meta
          name="twitter:description"
          content="Learn about the inspiring story behind 914United, our mission, and the founders who made it possible."
        />
        <meta name="twitter:image" content={acfData.our_story_image?.url} />
      </Helmet>
      <StoryHeader
        image={acfData.our_story_image?.url}
        title={acfData.our_story_main_title}
      />
      <StoryBody
        text={acfData.our_story_description}
        title={acfData.our_story_subtitle}
      />
      <FounderBody
        founders={acfData.our_founders}
        title={acfData.our_founders_subtitle}
      />
    </div>
  );
};

export default OurStory;
