import React from "react";
import { Link } from "react-router-dom";

const FeaturedNews = ({
  image,
  title,
  category,
  description,
  button_text,
  url,
}) => {
  return (
    <div className="pt-10 mx-auto px-5 md:px-20">
      <h1 className="text-4xl md:text-6xl font-bold pb-5">Featured News</h1>
      <hr></hr>

      <div className="md:flex py-10 ">
        <div className=" md:w-3/5 ">
          <img
            src={image.url}
            className="md:rounded-tl-md md:rounded-bl-md border border-white"
          />
        </div>

        <div className="md:w-2/5 bg-white px-4 py-auto items-center align-middle md:rounded-br-md md:rounded-tr-md ">
          <h2 className="text-4xl font-bold  text-black pt-5 md:pt-20">
            {title}
          </h2>
          <p className="text-black font-extralight pb-5 md:pb-10">{category}</p>
          <p className="text-md font-light text-black">{description}</p>
          <Link
            to={url}
            target="_blank"
            className="inline-block  mb-5 md:mb-0 mt-10 px-6 py-3 bg-red text-white font-bold uppercase  rounded-md hover:scale-110 ease-in-out duration-300"
          >
            {button_text}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturedNews;
