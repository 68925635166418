import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Facebook from "../../assets/facebook-white.svg";
import Instagram from "../../assets/instagram-white.svg";
import LinkedIn from "../../assets/linkedin-white.svg";
import { getPageBySlug } from "../../services/Wordpress";

const Footer = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("home");
        console.log("Fetched data:", data);
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching Home Page data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderNavItems = (items) => {
    return items.map((item) => {
      const isExternal = item.link.includes("https://");
      const LinkComponent = isExternal ? "a" : Link;
      const linkProps = isExternal
        ? { href: item.link, target: "_blank", rel: "noopener noreferrer" }
        : { to: item.link };

      return (
        <li className="pt-2" key={item.link}>
          <LinkComponent {...linkProps}>{item.name}</LinkComponent>
        </li>
      );
    });
  };

  const firstItems = acfData ? acfData.navbar_items.slice(0, 3) : [];
  const remainingItems = acfData ? acfData.navbar_items.slice(3) : [];

  return (
    <div className="md:h-[275px] bg-black pt-10">
      <div className="md:grid grid-cols-1 sm:grid-cols-3 px-10">
        <div className="text-white p-5 font-light">
          <h1 className="text-[20px] font-bold">914United</h1>
          <ul className="pt-3">{renderNavItems(firstItems)}</ul>
        </div>
        <div className="mx-auto justify-center">
          <ul className="text-white p-5 font-light">
            {renderNavItems(remainingItems)}
          </ul>
        </div>
        <div className="text-white ml-auto justify-end items-end pt-5">
          <div className="flex pb-4 ">
            <Link
              to={acfData && acfData.facebook_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="facebook" className="w-9 h-9 mr-2" />
            </Link>
            <Link
              to={acfData && acfData.instagram_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Instagram} alt="instagram" className="w-9 h-9" />
            </Link>
            <Link
              to={acfData && acfData.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={LinkedIn} alt="linkedin" className="w-9 h-9 ml-4" />
            </Link>
          </div>
          <h1 className="pb-2 ">
            <strong>Call Us:</strong> {acfData && acfData.phone_number}
          </h1>
          <h1 className="pb-10 md:pb-0">
            <strong>Email Us:</strong> {acfData && acfData.contact_email}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Footer;
