import React from "react";
import { Link } from "react-router-dom";

const VolunteerBar = ({ acfData }) => {
  return (
    <div className="bg-red py-5 md:py-2 md:h-[90px]">
      <div className="text-center font-bold text-[20px] md:text-[24px] text-white align-middle">
        <div className="flex flex-col items-center md:inline-flex md:flex-row md:max-w-[80%] pr-3">
          <span className="announcement-text py-2 md:py-0 pt-0 md:pt-3 mb-3 md:mb-0 mr-0 md:mr-10 text-center">
            {acfData.banner_text}
          </span>
          {acfData.banner_buttons.map((button, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <h2 className="text-white mx-3 md:mx-5 text-[20px] pt-5 md:pt-0">
                  OR
                </h2>
              )}
              <Link
                to={button.button_link}
                target={
                  button.button_link.includes("https://") ? "_blank" : "_self"
                }
                className="mt-3 inline-block px-4 py-2 mb-2 bg-white text-red font-semibold rounded-lg shadow-md hover:shadow-xl hover:scale-105 transition duration-300 text-[20px]"
              >
                {button.button_text}
              </Link>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VolunteerBar;
