import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import { motion } from "framer-motion";
import logo914 from "../../assets/914.jpeg";
import { getPageBySlug } from "../../services/Wordpress";

const Navbar = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("home");
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching About Us data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const renderNavItems = () => {
    if (!acfData || !acfData.navbar_items) return null;

    return acfData.navbar_items.map((item) => {
      const isExternal = item.link.includes("https://");
      const LinkComponent = isExternal ? "a" : Link;
      const linkProps = isExternal
        ? { href: item.link, target: "_blank", rel: "noopener noreferrer" }
        : { to: item.link };

      return (
        <LinkComponent
          key={item.link}
          {...linkProps}
          className={`mx-3 hover:text-red ease-in-out duration-300 ${
            location.pathname === item.link
              ? "font-bold text-red text-[17px]"
              : ""
          }`}
          onClick={() => setMenuOpen(false)}
        >
          {item.name}
        </LinkComponent>
      );
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <>
      <div className="h-[80px] p-3 border-b-2 bg-white border-b-red justify-between flex align-middle sticky top-0 text-black z-50">
        <div className="container mx-auto px-[20px] flex">
          {/* Logo */}
          <Link to="/">
            <img
              src={acfData?.nav_logo?.url || logo914}
              alt="914United Logo"
              className="h-[60px] w-auto mr-10"
              onClick={() => setMenuOpen(false)}
            />
          </Link>

          {/* Menu */}
          <nav className="hidden md:flex mr-auto">
            <ul className="flex ml-auto right-4 items-end pt-3">
              {renderNavItems()}
            </ul>
          </nav>

          <Link
            onClick={() => setMenuOpen(false)}
            to={acfData.navbar_button_link}
            className={`hover:text-red hover:bg-white border border-red ease-in-out inline-block px-6 pt-2 my-1 rounded-md bg-red text-white font-semibold shadow-md hover:shadow-xl transition duration-300`}
          >
            {acfData.navbar_button_text}
          </Link>
        </div>

        {/* Hamburger Menu Icon */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="text-black focus:outline-none"
          >
            {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: menuOpen ? "auto" : 0 }}
        className={`md:hidden bg-white border-t border-b border-gray-200 overflow-hidden fixed top-[80px] left-0 right-0 z-40`}
      >
        <ul className="flex flex-col items-center py-4">
          {acfData &&
            acfData.navbar_items &&
            acfData.navbar_items.map((item) => {
              const isExternal = item.link.includes("https://");
              const LinkComponent = isExternal ? "a" : Link;
              const linkProps = isExternal
                ? {
                    href: item.link,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }
                : { to: item.link };

              return (
                <LinkComponent
                  key={item.link}
                  {...linkProps}
                  className={`py-2 hover:text-red ease-in-out duration-300 ${
                    location.pathname === item.link
                      ? "font-bold text-red text-[17px]"
                      : ""
                  }`}
                  onClick={() => setMenuOpen(false)}
                >
                  {item.name}
                </LinkComponent>
              );
            })}
        </ul>
      </motion.div>
    </>
  );
};

export default Navbar;
