import React from "react";

const TeamHeader = ({ image, title }) => {
  return (
    <div className="relative">
      <img
        src={image}
        alt="image"
        className="w-full md:h-[630px] object-cover"
      />
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="absolute top-3/4 left-10 transform text-center text-white">
        <h1 className="text-6xl font-bold">{title}</h1>
      </div>
    </div>
  );
};

export default TeamHeader;
