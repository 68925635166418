import React, { useEffect, useRef } from "react";

const FounderBody = ({ founders, title }) => {
  const foundersRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    if (foundersRef.current) {
      observer.observe(foundersRef.current);
    }

    return () => {
      if (foundersRef.current) {
        observer.unobserve(foundersRef.current);
      }
    };
  }, []);

  return (
    <div className="bg-red text-white">
      <div className="md:p-20 p-5">
        <h1 className="text-5xl font-bold">{title}</h1>
        <hr className="bg-white my-5" />

        <div ref={foundersRef}>
          {founders.map((founder, index) => (
            <div key={index} className="md:grid grid-cols-2 py-10 ">
              <div>
                <img src={founder.image.url} alt={founder.name} />
              </div>
              <div>
                <div className="text-3xl pt-5 md:pt-0 pb-5">
                  <strong>{founder.name}</strong> {founder.position}
                </div>
                <p className="text-lg">{founder.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FounderBody;
