import axios from "axios";

const WORDPRESS_URL = "https://914unitedbackend.com/wp-json/wp/v2";

export const getPageBySlug = async (slug) => {
  try {
    const response = await axios.get(`${WORDPRESS_URL}/pages?slug=${slug}`);
    return response.data[0];
  } catch (error) {
    console.error("Error fetching page", error);
    throw error;
  }
};
