import React from "react";
import { Link } from "react-router-dom";

const Media = ({ media }) => {
  return (
    <div className="pt-10 mx-auto px-5 md:px-20">
      <h1 className="text-4xl md:text-6xl font-bold pb-5">Media</h1>
      <hr></hr>
      <div className="bg-red md:mx-10 md:px-8 pt-10">
        <div className="grid md:grid-cols-2 gap-8">
          {media.map((article, index) => (
            <Link
              to={article.media_url}
              target="_blank"
              key={index}
              className=" p-1 mb-10 bg-white text-black rounded-md hover:scale-105 shadow-md hover:shadow-xl ease-in-out duration-300 transition-all "
            >
              <div className="p-2 ">
                <div className="font-bold text-4xl mb-2 text-left">
                  {article.title}
                </div>
                <p className=" text-black font-extralight">
                  {article.company_name}
                </p>
              </div>
              <button className="bg-red hover:bg-white hover:text-red border border-red text-white font-bold py-2 px-4 rounded my-5 mx-auto justify-center items-center flex">
                Read More
              </button>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Media;
