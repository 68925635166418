import React from "react";

const BoardOfDirectors = ({ board }) => {
  return (
    <div className="pt-10">
      <h1 className="font-bold text-5xl px-5">Board Of Directors</h1>
      <hr className="bg-black my-5 mx-5" />
      <div className="grid md:grid-cols-5 gap-2 mx-5">
        {board.map((member, index) => (
          <div
            key={index}
            className="border-2 border-black bg-red text-white p-2 mb-10 rounded-md shadow-xl"
          >
            <img
              className="w-full h-[350px] md:h-[300px] object-cover rounded-t-md"
              src={member.image.url}
              alt={member.name}
            />
            <div className="p-2 h-[100px]">
              <div className="font-bold text-2xl mb-2 text-center">
                {member.name}
              </div>
              <p className="text-center">{member.position}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BoardOfDirectors;
