import React from "react";
import { Link } from "react-router-dom";

const SubscribeAndDonate = ({
  subscribe_title,
  subscribe_description,
  donate_title,
  donate_description,
  button_text,
  mailchimp,
}) => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2">
        <div className="bg-red p-10 text-white justify-center items-center mx-auto text-center">
          <h1 className="text-4xl font-bold">{subscribe_title}</h1>
          <div
            className="text-lg pt-4"
            dangerouslySetInnerHTML={{ __html: subscribe_description }}
          />

          <div className="pt-4 text-black">
            <form action={mailchimp} method="post" noValidate>
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                  <input
                    type="email"
                    name="EMAIL"
                    className="email required mce_inline_error p-2 w-3/4 rounded-md mb-4"
                    id="mce-EMAIL"
                    required
                    aria-required="true"
                    aria-invalid="true"
                    placeholder="Your Email Address"
                  />
                  <span
                    id="mce-EMAIL-HELPERTEXT"
                    className="helper_text"
                  ></span>
                </div>
                <div className="mc-field-group">
                  <input
                    type="text"
                    name="FNAME"
                    className="text p-2 w-3/4 rounded-md mb-4"
                    id="mce-FNAME"
                    placeholder="First Name"
                  />
                </div>
                <div hidden>
                  <input type="hidden" name="tags" value="2963748" />
                </div>
                <div id="mce-responses" className="clear foot">
                  <div
                    className="response"
                    id="mce-error-response"
                    style={{ display: "none" }}
                  ></div>
                  <div
                    className="response"
                    id="mce-success-response"
                    style={{ display: "none" }}
                  ></div>
                </div>
                <div
                  aria-hidden="true"
                  style={{ position: "absolute", left: "-5000px" }}
                >
                  <input
                    type="text"
                    name="b_4494c6d054bf6324d2acbd6f9_c1e3ffcfd5"
                    tabIndex="-1"
                    value=""
                  />
                </div>
                <div className="optionalParent">
                  <div className="clear foot">
                    <input
                      type="submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button w-1/3 rounded-md bg-white text-red p-2"
                      value="Subscribe"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="bg-white p-10 text-black justify-center items-center mx-auto text-center">
          <h1 className="text-4xl font-bold">{donate_title}</h1>
          <div
            className="text-lg pt-4"
            dangerouslySetInnerHTML={{ __html: donate_description }}
          />
          <div className="pt-4 mx-auto justify-center align-middle items-center">
            <Link
              to="/donate"
              className="mt-6 inline-block px-6 py-3 mb-2 bg-white text-black font-semibold rounded-lg shadow-md hover:shadow-xl transition duration-300"
            >
              {button_text}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeAndDonate;
