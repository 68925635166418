import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TeamHeader from "./TeamHeader";
import BoardOfDirectors from "./BoardOfDirectors";
import Staff from "./Staff";
import { getPageBySlug } from "../../services/Wordpress";

const OurTeam = () => {
  const [acfData, setAcfData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getPageBySlug("staff");
        setAcfData(data.acf);
      } catch (error) {
        console.error("Error fetching Our Team data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!acfData) {
    return <div>No data found</div>;
  }

  return (
    <div className="bg-white">
      <Helmet>
        <title>914United | Our Team</title>
        <meta
          name="description"
          content="Meet the dedicated team behind 914United. Learn about our Board of Directors and staff members who are committed to our mission."
        />
        <link rel="canonical" href="https://www.914united.com/teams" />
        <meta property="og:title" content="914United | Our Team" />
        <meta
          property="og:description"
          content="Meet the dedicated team behind 914United. Learn about our Board of Directors and staff members who are committed to our mission."
        />
        <meta property="og:image" content={acfData.main_image?.url} />
        <meta property="og:url" content="https://www.914united.com/teams" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="914United | Our Team" />
        <meta
          name="twitter:description"
          content="Meet the dedicated team behind 914United. Learn about our Board of Directors and staff members who are committed to our mission."
        />
        <meta name="twitter:image" content={acfData.main_image?.url} />
      </Helmet>
      <TeamHeader
        image={acfData.main_image?.url}
        title={acfData.our_team_title}
      />
      <BoardOfDirectors board={acfData.board_of_directors} />
      <Staff staff={acfData.staff} />
    </div>
  );
};

export default OurTeam;
