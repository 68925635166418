import React from "react";

const StoryHeader = ({ image, title }) => {
  return (
    <div className="relative">
      <img
        src={image}
        alt="landing page"
        className="w-full md:h-[630px] object-cover shadow-lg"
      />
      <div className="absolute inset-0 md:bg-black opacity-55"></div>
      <div className="md:absolute md:top-3/4 left-24 transform  md:text-white ">
        <h1 className="md:text-[60px] text-[30px] font-bold text-center md:text-left ">
          {title}
        </h1>
      </div>
    </div>
  );
};

export default StoryHeader;
